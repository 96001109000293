<template>
  <div class="hoteCatelList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>酒店分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>名称</span>
      <el-input
        placeholder="请输入栏目名称"
        v-model.trim="list.cateName"
      ></el-input>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <el-button
        type="primary"
        @click="addClassADialogVisible = true"
        v-if="$store.state.powerList.indexOf('hotel:type:list:insert')"
        >添加分类</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="分类ID">
      </el-table-column>
      <el-table-column align="center" prop="cateName" label="分类名称">
      </el-table-column>
      <el-table-column align="center" label="上级分类名称" prop="fatherName">
      </el-table-column>
      <el-table-column align="center" label="分类图片">
        <template slot-scope="{ row }">
          <el-image
            class="cateImg"
            style="width: 60px; height: 60px"
            :src="row.cateImg"
            :preview-src-list="[row.cateImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="最后修改时间"
        width="150"
      >
      </el-table-column>
      <el-table-column align="center" prop="statusStr" label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="row.status === 2"
            type="primary"
            @click="changeStatus(row.id, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-if="row.status === 1"
            type="primary"
            @click="changeStatus(row.id, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onDelCate(row)"
            v-if="$store.state.powerList.indexOf('hotel:type:list:delete')"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onEditCate(row)"
            v-if="$store.state.powerList.indexOf('hotel:type:list:update')"
            >编辑</el-button
          >
          <el-button size="mini" type="primary" @click="onSecondLevel(row)"
            >查看下级</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="添加一级分类"
      :visible.sync="addClassADialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            placeholder="请输入要添加的分类名称"
            v-model="addform.cateName"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="addClassADialogVisible"
            type="one"
            @addImageStr="(e) => onaddImageStr(e, 1)"
          ></send-image>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassADialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddCateOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑一级分类"
      :visible.sync="editClassADialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            placeholder="请输入要添加的分类名称"
            v-model="editform.cateName"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="editClassADialogVisible"
            type="one"
            :images="editform.cateImg"
            @addImageStr="(e) => onaddImageStr(e, 2)"
          ></send-image>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClassADialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditCateOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  hotelCateList,
  editSort,
  addHotel,
  hotelDetails,
  editHotelCate,
  delHotelCate,
  changeStatus,
} from "../../api/hotel";
import { sendFile } from "../../api/sendFile";
import sendImage from "../../components/sendImage.vue";
export default {
  components: { sendImage },
  name: "hoteCatelList",
  data() {
    return {
      ids: "",
      addClassADialogVisible: false,
      editClassADialogVisible: false,
      hotelDetailsDialogVisible: false,
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        fatherId: 0,
        cateName: "",
      },
      tableData: [],
      addform: {
        cateName: "",
        fatherId: 0,
        cateId: 0,
        cateImg: "",
      },
      editform: {
        cateName: "",
        fatherId: "",
        cateId: "",
        cateImg: "",
      },
      src: "",
    };
  },
  created() {
    this.getHotelCateList();
  },
  methods: {
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getHotelCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async getHotelCateList() {
      const { data } = await hotelCateList(this.list);
      this.pagination = data.pagination;
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
    },
    onSearch() {
      this.getHotelCateList();
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await editSort({
        cateId: row.id,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getHotelCateList();
    },
    onClose() {
      this.editform.cateImg = "";
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    onClose1() {
      this.src = "";
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    onaddImageStr(e, type) {
      console.log(e, type);
      if (type == 1) {
        this.src = e;
      } else if (type == 2) {
        this.editform.cateImg = e;
      }
    },

    sendAvatar() {
      this.$refs.fileRef.click();
    },

    async onAddCateOk() {
      if (this.addform.cateName === "" || this.src === "") {
        this.$message({
          type: "warning",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await addHotel({
        cateName: this.addform.cateName,
        fatherId: 0,
        cateId: 0,
        cateImg: this.src,
      });
      if (data.code === 0) {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.getHotelCateList();
        (this.cateName = ""), (this.src = "");
      }
      this.addClassADialogVisible = false;
      this.getHotelCateList();
      this.addform.cateImg = "";
      this.addform.fatherId = "";
      this.addform.cateId = "";
      this.addform.cateName = "";
      this.src = "";
    },
    async onEditCate(row) {
      const { data } = await hotelDetails({
        cateId: row.id,
      });
      console.log(data.data[0], "分类详情");
      this.editform.fatherId = data.data[0].fatherId;
      this.editform.cateImg = data.data[0].cateImg;
      this.editform.cateId = row.id;
      this.editform.cateName = row.cateName;
      this.editClassADialogVisible = true;
    },
    async onEditCateOk() {
      if (this.editform.cateName === "" || this.editform.cateImg === "") {
        return this.$message.warning("请把数据填写完整");
      }
      const { data } = await editHotelCate(this.editform);
      this.editform.cateName = "";
      this.editform.cateImg = "";
      this.editClassADialogVisible = false;
      this.getHotelCateList();
    },
    onDelCate(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delHotelCate({
            cateId: row.id,
          });
          this.getHotelCateList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    hotelCateDetails(row) {
      this.$router.push(`/hotelDetails/${row.id}`);
    },
    onSecondLevel(row) {
      this.$router.push(`/secondLevel/${row.id}`);
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.getHotelCateList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getHotelCateList();
    },
  },
};
</script>
<style lang="less" scoped>
.hoteCatelList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 30px;
    }
  }
  /deep/.el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      table {
        td {
          .cateImg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
  /deep/.el-form {
    .el-form-item {
      .el-form-item__content {
        .elbtn {
          margin-left: 10px;
          background-color: unset;
          width: 110px;
          height: 110px;
          text-align: center;
          line-height: 110px;
          color: #000;
          border: 1px solid #ccc;
        }
        display: flex;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
  }
}
</style>